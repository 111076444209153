import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Home from "./views/home/home";
import Policy from "./views/ConfidentialityPolicy/Policy";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/policy">
          <Policy />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
