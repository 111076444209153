import React, { useState } from "react";
import "./Policy.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Policy = () => {
  return (
    <div className={"policy-container"}>
      <h1>Politique de confidentialité :Sunland </h1>

      <p>
        ARTICLE 1 : PRÉAMBULE La présente politique de confidentialité a pour
        but d’informer les utilisateurs du site :   Sur la manière dont sont
        collectées leurs données personnelles. Sont considérées comme des
        données personnelles, toute information permettant d’identifier un
        utilisateur. A ce titre, il peut s’agir : de ses noms et prénoms, de son
        âge, de son adresse postale ou email, de sa localisation ou encore de
        son adresse IP (liste non-exhaustive) ; Sur les droits dont ils
        disposent concernant ces données ; Sur la personne responsable du
        traitement des données à caractère personnel collectées et traitées ;
        Sur les destinataires de ces données personnelles ; Sur la politique du
        site en matière de cookies. Cette politique complète les mentions
        légales et les Conditions Générales d’Utilisation consultables par les
        utilisateurs à l’adresse suivante :
      </p>
      <p>
        ARTICLE 2 : PRINCIPES RELATIFS À LA COLLECTE ET AU TRAITEMENT DES
        DONNÉES PERSONNELLES Conformément à l’article 5 du Règlement européen
        2016/679, les données à caractère personnel sont : Traitées de manière
        licite, loyale et transparente au regard de la personne concernée ;
        Collectées pour des finalités déterminées (cf. Article 3.1 des
        présentes), explicites et légitimes, et ne pas être traitées
        ultérieurement d'une manière incompatible avec ces finalités ;
        Adéquates, pertinentes et limitées à ce qui est nécessaire au regard des
        finalités pour lesquelles elles sont traitées ; Exactes et, si
        nécessaire, tenues à jour. Toutes les mesures raisonnables doivent être
        prises pour que les données à caractère personnel qui sont inexactes, eu
        égard aux finalités pour lesquelles elles sont traitées, soient effacées
        ou rectifiées sans tarder ; Conservées sous une forme permettant
        l'identification des personnes concernées pendant une durée n'excédant
        pas celle nécessaire au regard des finalités pour lesquelles elles sont
        traitées ; Traitées de façon à garantir une sécurité appropriée des
        données collectées, y compris la protection contre le traitement non
        autorisé ou illicite et contre la perte, la destruction ou les dégâts
        d'origine accidentelle, à l'aide de mesures techniques ou
        organisationnelles appropriées. Modèle réalisé sur LegalPlace.fr Le
        traitement n'est licite que si, et dans la mesure où, au moins une des
        conditions suivantes est remplie : La personne concernée a consenti au
        traitement de ses données à caractère personnel pour une ou plusieurs
        finalités spécifiques ; Le traitement est nécessaire à l'exécution d'un
        contrat auquel la personne concernée est partie ou à l'exécution de
        mesures précontractuelles prises à la demande de celle-ci ; Le
        traitement est nécessaire au respect d'une obligation légale à laquelle
        le responsable du traitement est soumis ; Le traitement est nécessaire à
        la sauvegarde des intérêts vitaux de la personne concernée ou d'une
        autre personne physique ; Le traitement est nécessaire à l'exécution
        d'une mission d'intérêt public ou relevant de l'exercice de l'autorité
        publique dont est investi le responsable du traitement ; Le traitement
        est nécessaire aux fins des intérêts légitimes poursuivis par le
        responsable du traitement ou par un tiers, à moins que ne prévalent les
        intérêts ou les libertés et droits fondamentaux de la personne concernée
        qui exigent une protection des données à caractère personnel, notamment
        lorsque la personne concernée est un enfant.
      </p>

      <p>
        ARTICLE 3 : DONNÉES À CARACTÈRE PERSONNEL COLLECTÉES ET TRAITÉES DANS LE
        CADRE DE LA NAVIGATION SUR LE SITE Article 3.1 : Données collectées Les
        données personnelles collectées dans le cadre de notre activité sont les
        suivantes : / La collecte et le traitement de ces données répond à la
        (aux) finalité(s) suivante(s) : / Article 3.2 : Mode de collecte des
        données Lorsque vous utilisez notre site, sont automatiquement
        collectées les données suivantes : / D’autres données personnelles sont
        collectées lorsque vous effectuez les opérations suivantes sur la
        plateforme : / Elles sont conservées par le responsable du traitement
        dans des conditions raisonnables de sécurité, pour une durée de :
        [Indiquer la durée de conservation prévue] La société est susceptible de
        conserver certaines données à caractère personnel au-delà des délais
        annoncés ci-dessus afin de remplir ses obligations légales ou
        réglementaires. Article 3.3 : Hébergement des données Le site Sunland
        est hébergé par : Adresse du siège social Contact (adresse email et/ou
        numéro de téléphone)]  
      </p>
      <p>
        ARTICLE 4 : RESPONSABLE DU TRAITEMENT DES DONNÉES ET DÉLÉGUÉ À LA
        PROTECTION DES DONNÉES   Article 4.1 : Le responsable du traitement des
        données Les données à caractère personnelles sont collectées par [raison
        ou dénomination sociale de l’entreprise], [forme juridique] au capital
        de [montant du capital social], dont le numéro d’immatriculation est le
        [indiquez votre numéro RCS]. Le responsable du traitement des données à
        caractère personnel peut être contacté de la manière suivante : Par
        courrier à l’adresse : [indiquez l’adresse du siège social] ; Par
        téléphone, au [numéro de téléphone] ; Par mail : [email du responsable
        du traitement des données]. Modèle réalisé sur LegalPlace.fr Article 4.2
        : Le délégué à la protection des données   Le délégué à la protection
        des données de l’entreprise ou du responsable est :   [Indiquez
        l’identité, l’adresse, le numéro de téléphone et/ou l’adresse email du
        DPO]   Si vous estimez, après nous avoir contactés, que vos droits
        “Informatique et Libertés”, ne sont pas respectés, vous pouvez adresser
        une information à la CNIL.
      </p>
      <p>
        ARTICLE 5 : LES DROITS DE L’UTILISATEUR EN MATIÈRE DE COLLECTE ET DE
        TRAITEMENT DES DONNÉES Tout utilisateur concerné par le traitement de
        ses données personnelles peut se prévaloir des droits suivants, en
        application du règlement européen 2016/679 et de la Loi Informatique et
        Liberté (Loi 78-17 du 6 janvier 1978) : Droit d’accès, de rectification
        et droit à l’effacement des données (posés respectivement aux articles
        15, 16 et 17 du RGPD) ;   Droit à la portabilité des données (article 20
        du RGPD) ;   Droit à la limitation (article 18 du RGPD) et à
        l’opposition du traitement des données (article 21 du RGPD) ;  
        Politique de confidentialitéSunland
      </p>
    </div>
  );
};

export default Policy;
